body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:url('./images/bg.jpg'),lightgray 50% / cover no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
}

.Home{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap:10px;
  padding:20px;
}
.contacts{
  grid-column-start: 1;
  grid-column-end:2;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
}
.contacts a{
  text-decoration: none;
}
table{
  border-collapse: collapse;
}
th,td{
  text-align: left;
  padding:8px;
}
.buttonArea{
  grid-column-start: 2;
  grid-column-end:3;
}
.Header{
  display: grid;
  grid-template-columns: 3fr 1fr;

}
.headerPhotoArea{
  position: relative;
  width:500px;
  height:500px;
  overflow: hidden;
  border-radius: 100%;
}
.headerPhotoArea img{
  margin-left: 20%;
  width:100%;
}
.divider{
  width:100%;
  height:2px;
  background-color: black;
}
.headerText{
  font-family:'Times New Roman', Times, serif;
  font-size: 70px;
  text-align: end;
  margin-top: 20px;
}
.headerSubtext{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  text-align: end;
}
.Textarea{
  margin-top: 15%;
}
@media  screen and (max-width:600px) {
  .Home{
    grid-template-rows: 1fr 1fr ;
    grid-template-columns: 1fr;
  }
  .contacts{
    grid-column-start: 1;
    grid-column-end:2;
    grid-row-start: 1;
    grid-row-end:2;
  }
  .buttonArea{
    grid-column-start: 1;
    grid-column-end:2;
    grid-row-start: 2;
    grid-row-end:3;
  }
  .Header{
    margin-top:20px;
  }
  .headerPhotoArea{
    width:150px;
    height:150px;
  }
  .headerText{
    font-size: 30px;
  }
  .headerSubtext{
    font-size: 15px;
  }
  .Textarea{
    margin-top: 5%;
  }
}